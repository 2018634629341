<template>
  <SfSidebar
    :visible="!!isOpen"
    :title="filterTitle"
    class="m-sidebar-filters-inner sidebar-inner"
    :class="'m-sidebar-filters-inner--' + filterName"
    @close="closeFilter"
  >
    <ALoadingSpinner
        v-show="productsIsLoading"
        :size="24"
        :weight="3"
        :is-absolute-position="true"
    />
    <template #content-top>
      <SfButton
        v-if="getCurrentFilters.hasOwnProperty('price') || getCurrentFiltersSearch.hasOwnProperty('price') && filterName === 'price'"
        class="price-filters__button-clear sf-button--text form__action-button form__action-button--secondary"
        :class="{'price-filters__button-clear-disabled': false}"
        data-transaction-name="Filter - Clear Price"
        @click="clearPriceFilter()"
      >
        {{ $t('Clear') }}
      </SfButton>
    </template>
    <template #circle-icon>
      <SfButton
        class="sf-button--close"
        data-transaction-name="Filter - Close"
        @click="closeFilter"
      >
        <SfIcon
          icon="cross"
          size="xxs"
          color="secondary"
        />
      </SfButton>
    </template>
    <div class="filters">
      <div v-show="filterName === 'sort'">
        <div class="radio-switcher-text">
          <SfRadio
            v-for="option in sortOptions"
            :key="option.id"
            :value="option.id"
            :label="$t(option.label)"
            :selected="sortOrder"
            @input="selectSort"
          />
        </div>
      </div>
      <div v-if="isVaruscafeCategory(filterName)">
        <MVaruscafeMobileFilterInner
          :available-filters="availableFilters[filterName]"
          :is-filter-active="isFilterActive"
          :filter-name="filterName"
        />
      </div>
      <div v-show="isColorFilter(filterName)">
        <div class="filters__colors">
          <SfColor
            v-for="filter in availableFilters[filterName]"
            :key="filter.id"
            :color="filter.color"
            :selected="isFilterActive(filter)"
            class="filters__color"
            data-transaction-name="Filter - Change"
            @click="changeFilter(filter)"
          />
        </div>
      </div>
      <div v-show="!isColorFilter(filterName) && isBrandFilter(filterName)">
        <div class="search-input">
          <SfInput
            v-model="searchString"
            :label="$t('Find TM')"
            class="brand-input sf-input--filled sf-input-has-clear"
            data-transaction-name="Filter - Search - Field"
          />
          <SfButton
            v-if="searchString.length"
            class="cancel-button sf-button--text form__action-button form__action-button--secondary`"
            data-transaction-name="Filter - Search - Clear"
            @click="clearSearch"
          >
            <SfIcon
              icon="cross"
              size="13px"
              color="secondary"
            />
          </SfButton>
        </div>
        <SfFilter
          v-for="filter in filteredBrandsFilters"
          v-if="!filter.open"
          :key="filter.id"
          :label="filter.label"
          :count="filter.count"
          :color="filter.color"
          :selected="isFilterActive(filter)"
          class="filters__item"
          :class="{'filters__item--disable': filter.count === 0 && !isFilterActive(filter)}"
          @change="changeFilter(filter, filterName, isFilterActive(filter))"
        />
      </div>
      <div v-if="filterName === 'price'" class="price-filters">
        <div class="price-filters-inputs">
          <SfInput
            :value="getMinPrice"
            type="number"
            class="price-filters-inputs__input sf-input--filled sf-input-has-clear"
            data-transaction-name="Filter - Price From - Field"
            @input="changeMinPrice($event)"
          />
          <div class="price-filters-inputs__line" />
          <SfInput
            :value="getMaxPrice"
            type="number"
            class="price-filters-inputs__input sf-input--filled sf-input-has-clear"
            data-transaction-name="Filter - Price To - Field"
            @input="changeMaxPrice($event)"
          />
        </div>
        <SfButton
          class="price-filters__button sf-button--text form__action-button form__action-button--secondary"
          data-transaction-name="Filter - Price - Apply"
        >
          {{ $t('Apply price') }}
        </SfButton>
      </div>
      <div v-else-if="isVarusPerfect || isSpecialOffers" v-show="!isColorFilter(filterName) && !isBrandFilter(filterName)">
        <SfFilter
          v-for="filter in availableFilters[filterName]"
          v-if="!filter.open"
          :key="filter.id"
          :label="filter.label"
          :count="filter.count"
          :color="filter.color"
          :selected="isFilterActivePerfect(filter)"
          class="filters__item"
          :class="{'filters__item--disable': filter.count === 0 && !isFilterActivePerfect(filter)}"
          @change="changeFilter(filter, filterName, isFilterActivePerfect(filter))"
        />
      </div>
      <div
        v-else
        v-show="!isColorFilter(filterName) && !isBrandFilter(filterName) && !isVaruscafeCategory(filterName)"
      >
        <SfFilter
          v-for="filter in availableFilters[filterName]"
          v-if="!filter.open"
          :key="filter.id"
          :label="filter.label"
          :count="filter.count"
          :color="filter.color"
          :selected="isFilterActive(filter)"
          class="filters__item"
          :class="{'filters__item--disable': filter.count === 0 && !isFilterActive(filter)}"
          @change="changeFilter(filter, filterName, isFilterActive(filter))"
        />
      </div>
    </div>
    <template #content-bottom>
      <div class="filters__buttons">
        <SfButton
          v-if="filterName === 'price'"
          class="sf-button--primary sf-button--full-width"
          :class="{'price-filters__button-disabled': priceSubmitDisabled}"
          :disabled="priceSubmitDisabled"
          data-transaction-name="Filter - Close"
          @click="closeFilterApply"
        >
          {{ $t("Apply") }}
          <span
            v-if="filtersCount"
            class="filters__buttons--count"
          >
            ({{ filtersCount }})
          </span>
        </SfButton>
        <SfButton
          v-else
          class="sf-button--primary sf-button--full-width"
          data-transaction-name="Filter - Close"
          @click="closeFilterApply"
        >
          {{ filterName === 'sort' ? $t("Sort") : $t("Apply") }}
          <span
            v-if="filtersCount"
            class="filters__buttons--count"
          >
            ({{ filtersCount }})
          </span>
        </SfButton>
      </div>
    </template>
  </SfSidebar>
</template>
<script>
import config from 'config'
import castArray from 'lodash/castArray';
import { SfSidebar, SfButton, SfIcon, SfRadio, SfFilter, SfColor } from '@storefront-ui/vue';
import { mapGetters } from 'vuex';
import SfInput from 'theme/components/storefront-override/SfInput'
import { getCleanedPhone } from 'theme/helpers/text';
import MVaruscafeMobileFilterInner from 'theme/components/molecules/m-varuscafe-mobile-filter-inner';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';

export default {
  name: 'MSidebarFiltersInner',
  components: {
    ALoadingSpinner,
    SfSidebar,
    MVaruscafeMobileFilterInner,
    SfButton,
    SfIcon,
    SfRadio,
    SfFilter,
    SfColor,
    SfInput
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isSearchPage: {
      type: Boolean,
      default: false
    },
    isVarusPerfect: {
      type: Boolean,
      default: false
    },
    isSpecialOffers: {
      type: Boolean,
      default: false
    },
    filterName: {
      type: String,
      default: ''
    },
    sortOptions: {
      type: Array,
      default: () => ([])
    },
    availableFilters: {
      type: Object,
      default: () => ({})
    },
    filtersCount: {
      type: Number,
      default: null
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    sortOrder: {
      type: String,
      default: ''
    },
    activedFilters: {
      type: Object,
      required: false,
      default: () => ({})
    },
    productsIsLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedSort: '',
      selectedFilters: [],
      searchString: '',
      minimalPrice: '',
      maximalPrice: '',
      priceSubmitDisabled: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentFilters: 'category-extension/getCurrentFilters',
      getCurrentFiltersSearch: 'search-result/getCurrentFilters',
      getAggregations: 'category-extension/getAggregations',
      getAggregationsSearch: 'search-result/getAggregations'
    }),
    filterTitle () {
      switch (this.filterName) {
        case 'sort':
          return this.$t('Sort By')
        case 'color_filter':
          return this.$t('Color')
        case 'price_filter':
          return this.$t('Price')
        case 'price':
          return `${this.$t('Price')}, грн`
        case 'size_filter':
          return this.$t('Size')
        default:
          return this.filterLabels[this.filterName]
      }
    },
    isFilterActive () {
      if (this.isSearchPage) {
        return filter =>
          castArray(this.getCurrentFiltersSearch[filter.type]).find(
            variant => variant && variant.id?.toString() === filter.id?.toString()
          ) !== undefined;
      } else {
        return filter =>
          castArray(this.getCurrentFilters[filter.type]).find(
            variant => variant && variant.id?.toString() === filter.id?.toString()
          ) !== undefined;
      }
    },
    isFilterActivePerfect () {
      return filter =>
        castArray(this.activedFilters[filter.type]).find(
          variant => variant && variant.id?.toString() === filter.id?.toString()
        ) !== undefined;
    },
    filteredBrandsFilters () {
      const brandFilters = this.availableFilters[config.attributesCodes.pimBrandId] || this.availableFilters['brand'] || []
      let filteredFilters = []

      if (this.searchString) {
        filteredFilters = brandFilters.filter(e => e.label?.toLowerCase().includes(this.searchString.toLowerCase()))
      } else {
        filteredFilters = brandFilters
      }

      return filteredFilters
    },
    getMinPrice () {
      if (this.isSearchPage) {
        if (this.minimalPrice === '') {
          return Math.round(this.availableFilters?.price?.from || 0)
        } else {
          return this.minimalPrice
        }
      } else {
        if (this.minimalPrice === '') {
          return this.getAggregations.agg_price.filter.price_min.value.toFixed(0)
        } else {
          return this.minimalPrice
        }
      }
    },
    getMaxPrice () {
      if (this.isSearchPage) {
        if (this.maximalPrice === '') {
          return Math.round(this.availableFilters?.price?.to || 0)
        } else {
          return this.maximalPrice
        }
      } else {
        if (this.maximalPrice === '') {
          return this.getAggregations.agg_price.filter.price_max.value.toFixed(0)
        } else {
          return this.maximalPrice
        }
      }
    }
  },
  methods: {
    closeFilter () {
      this.$emit('close')
    },
    closeFilterApply () {
      const isSearchPage = this.isSearchPage;

      if (this.filterName === 'price') {
        const maxPrice = isSearchPage
          ? this.availableFilters.price.to
          : this.getAggregations.agg_price?.filter?.price_max?.value;

        if (this.minimalPrice === '') {
          this.minimalPrice = this.availableFilters.price.from
        } else {
          this.minimalPrice = Number(this.minimalPrice) >= maxPrice
            ? Number(Math.trunc(maxPrice)) : Number(this.minimalPrice)
        }
        if (this.maximalPrice === '' || this.minimalPrice > this.maximalPrice) {
          this.maximalPrice = Number(this.availableFilters.price.from) + 1
        }

        this.$emit('submit-filter-price', this.minimalPrice, this.maximalPrice)
      }

      this.closeFilter()
    },
    selectSort (value) {
      this.$emit('change-sort-order', value)
    },
    changeFilter (filter, filterType, active) {
      const currentFilterValues = this.availableFilters[filterType]

      this.$emit('change', {
        filter, currentFilterValues, filterType, active
      });
    },
    clearPriceFilter () {
      let price = {
        attribute_code: 'price'
      }
      this.$emit('change', price, 'price')
      this.minimalPrice = ''
      this.maximalPrice = ''
      this.$store.dispatch('category-extension/filterCategoryPrice', {});
      this.$store.dispatch('search-result/filterCategoryPrice', {});
      this.closeFilter()
    },
    isColorFilter (filter) {
      return filter === config.attributesCodes.color
    },
    isBrandFilter (filter) {
      return filter === config.attributesCodes.pimBrandId || filter === 'brand'
    },
    isVaruscafeCategory (filter) {
      return filter === config.attributesCodes.varusCafe
    },
    clearSearch () {
      this.searchString = ''
    },
    changeMinPrice (minPrice) {
      this.minimalPrice = Math.trunc(minPrice)
      this.priceSubmitDisabled = !(getCleanedPhone(minPrice) && Number(minPrice) >= 0);
    },
    changeMaxPrice (maxPrice) {
      this.maximalPrice = Number(maxPrice).toFixed(0)
      this.priceSubmitDisabled = !(getCleanedPhone(maxPrice) && Number(maxPrice) > 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-sidebar-filters-inner {
  --sidebar-top-padding: 0;
  --sidebar-content-padding: var(--spacer-20);
  --bar-padding: var(--spacer-14) var(--spacer-20);

  .radio-switcher-text {
    display: block;
  }

  ::v-deep {
    .sf-radio {
      font-size: var(--font-sm);
    }
  }

    ::v-deep .sf-bar {
      --bar-font-weight: var(--font-medium);
      --bar-font-size: var(--font-size-20);

      div:nth-child(2) {
        width: 100%;
      }

      &__title {
        margin-left: var(--spacer-15);
      }
    }

    ::v-deep .sf-radio {
      margin-bottom: var(--spacer-20);

      &__content {
        padding: 0;
      }

      &__label {
        font-size: var(--font-size-14);
      }
    }

  .filters {
    &__item {
      margin-bottom: var(--spacer-15);

      &:first-child {
        margin-top: var(--spacer-20);
      }

      &--disable {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .search-input {
      position: relative;

      .brand-input {
        margin-top: 0;
        margin-bottom: var(--spacer-15);

        @include for-desktop {
          margin-top: var(--spacer-15);
          margin-bottom: var(--spacer-15);
        }
      }

      .cancel-button {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -7px;
        z-index: 1;
      }

      ::v-deep {
        input {
          height: var(--spacer-40);
        }

        .sf-input__wrapper {
          margin: 0;
        }

        .sf-input__error-message {
          display: none;
        }
      }
    }
  }

  ::v-deep {
    .sf-filter {
      --filter-label-margin: 0 0 0 var(--spacer-10);
      --filter-label-text-decoration: none;
      --filter-label-transform: none;
      --filter-label-color: var(--black);
      --filter-label-font-size: var(--font-sm);
      --filter-label-font-weight: var(--font-normal);
      --filter-count-margin: 0 0 0 var(--spacer-15);
      --filter-count-color: var(--dark-gray);
      --filter-count-font-size: var(--font-sm);

      &__label {
        font-size: var(--font-sm);
      }

      &__count {
        font-size: var(--font-sm);
        top: 0!important;
      }
    }
  }
}

.sf-button--close {
  position: absolute;
  right: var(--spacer-base);
  top: var(--spacer-15);
  padding: var(--spacer-xs);
  background-color: var(--light-gray);
  border-radius: 5px;

  .sf-icon.size-xxs {
    --icon-size: .88rem;
  }
}

.filters__buttons--count {
  margin-left: var(--spacer-10);
}

.price-filters {

  .price-filters-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__line {
      display: block;
      min-width: var(--spacer-12);
      height: 1px;
      margin: 0 var(--spacer-10);
      background-color: var(--black);
    }

  }

  &__button {
    margin-top: var(--spacer-10);
    width: 100%;
    height: px2rem(40);
    display: none;
    align-items: center;
    justify-content: center;
    background: var(--orange);
    font-size: var(--font-size-14);
    color: var(--white);
    --button-text-decoration: none;
  }

  ::v-deep {
    input {
      padding: var(--spacer-11) var(--spacer-16);
      height: var(--spacer-40);
    }

    .sf-input__wrapper {
      margin: 0;
    }

    .sf-input__error-message {
      display: none;
    }
  }
}
.price-filters__button-disabled {
  opacity: 0.4;
}

.price-filters__button-clear {
  margin-top: var(--spacer-20);
  font-size: var(--font-size-14);
  font-weight: normal;
  color: var(--orange);
  --button-text-decoration: none;

  &:active {
    --button-background: none!important;
  }
}

::v-deep .sf-sidebar__top {
  position: absolute;
  right: var(--spacer-80);
}
</style>
