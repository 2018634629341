<template>
  <SfHeading class="sf-heading--left">
    <template #title>
      <p class="sf-heading__title sf-heading__title--h4">
        {{ titleText }}
      </p>
    </template>
    <template #subtitle>
      <p v-if="subtitleText" class="sf-heading__subtitle">
        {{ subtitleText }}
      </p>
      <SfButton
        v-if="!isNoResultInCity"
        class="sf-button--base sf-button--mq"
        data-transaction-name="Search - Change Location"
        @click.native="openShippingModal()"
      >
        {{ $t('Change city') }}
      </SfButton>
    </template>
  </SfHeading>
</template>
<script>
import { SfHeading, SfButton } from '@storefront-ui/vue';
import Delivery from 'theme/mixins/Delivery';

export default {
  name: 'ASearchResultEmpty',
  components: {
    SfHeading,
    SfButton
  },
  mixins: [Delivery],
  props: {
    isNoResultInCity: {
      type: Boolean,
      default: false
    },
    isNoResultInSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleText () {
      if (this.isNoResultInSearch) {
        return this.$t('No products found in the catalog')
      }

      return this.isNoResultInCity
        ? this.$t('No products found in the catalog')
        : this.$t('There are no products from this category in this city')
    },
    subtitleText () {
      return this.isNoResultInCity
        ? this.$t('Try using a different combination of filters')
        : this.$t('Please select another city or another category')
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@storefront-ui/shared/styles/helpers/breakpoints';

.sf-heading {
  @media (min-width: $tablet-min) {
    padding-top: var(--spacer-base);
  }

  &__title {
    color: var(--dark-gray);
    font-weight: var(--font-medium);
  }

  &__subtitle {
    --heading-subtitle-font: var(--font-sm);

    @media (min-width: $tablet-min) {
      --heading-subtitle-font: var(--font-base);
    }
  }
}

::v-deep {
  .sf-button {
    margin-top: var(--spacer-base);
  }
}

.sf-heading__title {
  line-height: 25px;
  margin-bottom: 18px;
}

.sf-heading__subtitle {
  color: var(--black);
}
</style>
