import { RobotsEnum } from './types';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';

const replacePlaceholderPhrase = (phraseFromAdmin, query) => {
  const regexPhrase = /{phrase}/gi;
  return phraseFromAdmin?.replace(regexPhrase, `${query}`)
};

export function metaSearch () {
  const metaTitle = this.$store.getters['search-result/getMetaTags'][0];
  const metaDescription = this.$store.getters['search-result/getMetaTags'][1];
  const query = this.$route.query.q;
  const relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  const meta = {
    title: replacePlaceholderPhrase(metaTitle, query),
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content: replacePlaceholderPhrase(metaDescription, query)
      }
    }
  };

  return mergeMeta(meta)
}
