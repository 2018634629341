<template>
  <LazyHydrate
    :trigger-hydration="isMounted"
    class="m-accordion"
    :class="{'show-more': showMoreChange}"
  >
    <div>
      <div
        class="m-accordion__item m-accordion__item--active"
        v-if="availableFilters.price !== undefined"
        :key="availableFilters.price.type"
        :class="className(availableFilters.price.type)"
      >
        <label
          class="m-accordion__label"
          :for="availableFilters.price.type"
        >
          <input
            :id="availableFilters.price.type"
            :value="availableFilters.price.type"
            class="m-accordion__input"
            type="checkbox"
            v-model="filtersChecked"
          >
          <span>{{ $t('Price') }}, грн</span>

          <SfChevron />
        </label>
        <div class="m-accordion__content">
          <div class="price-filters">
            <div class="price-filters-inputs">
              <SfInput
                :value="minimalPrice"
                type="number"
                class="price-filters-inputs__input sf-input--filled sf-input-has-clear"
                data-transaction-name="Filter - Price From - Field"
                @input="changeMinimalPrice($event)"
              />
              <div class="price-filters-inputs__line" />
              <SfInput
                :value="maximalPrice"
                type="number"
                class="price-filters-inputs__input sf-input--filled sf-input-has-clear"
                data-transaction-name="Filter - Price To - Field"
                @input="changeMaximalPrice($event)"
              />
            </div>
            <SfButton
              class="price-filters__button sf-button--text form__action-button form__action-button--secondary"
              :class="{'price-filters__button-disabled': priceSubmitDisabled}"
              data-transaction-name="Filter - Submit Price"
              @click="submitFilterPrice()"
              :disabled="priceSubmitDisabled"
            >
              {{ $t('Apply price') }}
            </SfButton>
            <SfButton
              v-if="getCurrentFilters.hasOwnProperty('price')"
              class="price-filters__button-clear sf-button--text form__action-button form__action-button--secondary"
              :class="{'price-filters__button-clear-disabled': false}"
              data-transaction-name="Filter - Clear Price"
              @click="clearPriceFilter()"
            >
              {{ $t('Clear') }}
            </SfButton>
          </div>
        </div>
      </div>
      <div
        v-for="(filters, filterType) in availableFilters"
        class="m-accordion__item m-accordion__item--active"
        :key="filterType"
        :class="className(filterType)"
        v-if="filterType !== 'has_promotion_in_stores' && filterType !== 'price' && !disablePimBrandId(filterType)"
      >
        <label
          class="m-accordion__label"
          :for="filterType"
        >
          <input
            :id="filterType"
            :value="filterType"
            class="m-accordion__input"
            type="checkbox"
            v-model="filtersChecked"
          >
          <span> {{ filterLabels[filterType] || filterType }}</span>

          <SfChevron />
        </label>
        <div class="m-accordion__content">
          <template v-if="isColorFilter(filterType)">
            <div
              :key="filterType + 'filter'"
              class="filters__colors"
            >
              <component
                :is="getFilterComponent(filter)"
                :href="getFilterHref(filter)"
                v-for="filter in filters"
                :key="filter.id"
              >
                <SfColor
                  :color="filter.color"
                  :selected="isFilterActive(filter)"
                  class="filters__color"
                  data-transaction-name="Filter - Change Filter"
                  @click="changeFilter(filter)"
                />
              </component>
            </div>
          </template>
          <template v-else-if="isBrandFilter(filterType)">
            <div class="search-input">
              <SfInput
                v-model="searchString"
                :label="$t('Find TM')"
                class="brand-input sf-input--filled sf-input-has-clear"
                data-transaction-name="Filter - Search - Field"
                @input="changeSearchBrands($event)"
              />
              <SfButton
                v-if="searchString.length"
                class="cancel-button sf-button--text form__action-button form__action-button--secondary"
                data-transaction-name="Filter - Clear Search"
                @click="clearSearch"
              >
                <SfIcon
                  icon="cross"
                  size="13px"
                  color="secondary"
                />
              </SfButton>
            </div>
            <div
              class="m-accordion__content-wrapper"
              :class="{
                'm-accordion__content-wrapper--open': filtersOpen(filteredBrandsFilters, filterType, activeSearchBrand)
              }"
            >
              <component
                :is="getFilterComponent(filter)"
                :href="getFilterHref(filter)"
                v-for="filter in getFilters(filteredBrandsFilters, filterType)"
                v-if="!filter.open"
                :key="filter.id"
              >
                <SfFilter
                  :label="filter.label"
                  :count="filter.count"
                  :color="filter.color"
                  :selected="isFilterActive(filter)"
                  class="filters__item"
                  :class="{'filters__item--disable': filter.count === 0 && !isFilterActive(filter)}"
                  @change="changeFilter(filter, filterType , isFilterActive(filter))"
                />
              </component>
            </div>
            <SfButton
              v-if="Object.keys(filteredBrandsFilters).length > 5 && countFiltersMore(filteredBrandsFilters) !==0 && !activeSearchBrand && !isShowMore(filteredBrandsFilters, filterType)"
              class="filters__button-more"
              @click="showMore(filteredBrandsFilters, filterType)"
            >
              {{ $t('More') }} <span class="filters__button-more--count"> {{ countFiltersMore(filteredBrandsFilters) }}</span>
            </SfButton>
            <SfButton
              v-if="!activeSearchBrand && isShowMore(filteredBrandsFilters, filterType)"
              class="filters__button-more"
              @click="showMore(filteredBrandsFilters, filterType)"
            >
              {{ $t('Hide') }}
            </SfButton>
          </template>
          <template v-else>
            <div
              class="m-accordion__content-wrapper"
              :class="{'m-accordion__content-wrapper--open': filtersOpen(filters, filterType)}"
            >
              <component
                :is="getFilterComponent(filter)"
                :href="getFilterHref(filter)"
                v-for="filter in getFilters(filters, filterType)"
                v-if="!filter.open"
                :key="filter.id"
              >
                <SfFilter
                  :label="filter.label"
                  :count="filter.count"
                  :color="filter.color"
                  :selected="isFilterActive(filter)"
                  class="filters__item"
                  :class="{'filters__item--disable': filter.count === 0 && !isFilterActive(filter)}"
                  @change="changeFilter(filter, filterType, isFilterActive(filter))"
                />
              </component>
            </div>
            <SfButton
              v-if="Object.keys(filters).length > 5 && countFiltersMore(filters) !==0 && !isShowMore(filters, filterType)"
              class="filters__button-more"
              @click="showMore(filters, filterType)"
            >
              {{ $t('More') }} <span class="filters__button-more--count"> {{ countFiltersMore(filters) }}</span>
            </SfButton>
            <SfButton
              v-if="isShowMore(filters, filterType)"
              class="filters__button-more"
              @click="showMore(filters, filterType)"
            >
              {{ $t('Hide') }}
            </SfButton>
          </template>
        </div>
      </div>
    </div>
  </LazyHydrate>
</template>

<script>
import config from 'config'
import castArray from 'lodash/castArray'
import {
  SfColor,
  SfFilter,
  SfChevron,
  SfButton,
  SfIcon
} from '@storefront-ui/vue'
import { mapGetters } from 'vuex'
import SfInput from 'theme/components/storefront-override/SfInput'
import { isServer } from '@vue-storefront/core/helpers'
import LazyHydrate from 'vue-lazy-hydration'
import { getFilterLink } from '../../helpers/filterHelpers'

export default {
  name: 'MSidebarFilter',
  components: {
    SfColor,
    SfFilter,
    SfChevron,
    SfInput,
    SfButton,
    SfIcon,
    LazyHydrate
  },
  props: {
    availableFilters: {
      type: Object,
      default: () => ({})
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    activedFilters: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isVarusCafePage: {
      type: Boolean,
      default: false
    },
    hideOnBrandPage: {
      type: Boolean,
      default: false
    },
    linkBuilder: {
      type: Boolean,
      default: true
    },
    priceFromFilter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filtersChecked: [],
      searchString: '',
      minimalPrice: '',
      maximalPrice: '',
      showMoreChange: false,
      isMounted: false,
      activeSearchBrand: false
    }
  },
  mounted () {
    if (!isServer) {
      localStorage.setItem('category/filters/filterType', JSON.stringify([]))
    }
    this.isMounted = true
  },
  beforeDestroy () {
    localStorage.setItem('category/filters/filterType', JSON.stringify([]))
  },
  methods: {
    showMore (filters, filterType) {
      if (filterType !== 'price' && !isServer) {
        const localFilterType = JSON.parse(localStorage.getItem('category/filters/filterType') || '[]');
        const newLocalFilterType = localFilterType.includes(filterType)
          ? localFilterType.filter(elem => elem !== filterType)
          : [...localFilterType, filterType];
        localStorage.setItem('category/filters/filterType', JSON.stringify(newLocalFilterType));
      }
      this.showMoreChange = !this.showMoreChange;
    },
    isShowMore (filters, filterType) {
      if (filterType === 'price' || isServer) {
        return false
      }
      const localFilterType = JSON.parse(localStorage.getItem('category/filters/filterType') || '[]')
      return localFilterType.includes(filterType)
    },
    changeFilter (filter, filterType, active) {
      const currentFilterValues = this.availableFilters[filterType]

      this.$emit('change', {
        filter, currentFilterValues, filterType, active
      });
    },
    clearPriceFilter () {
      let price = {
        attribute_code: 'price'
      }
      this.$emit('change', price, 'price')
    },
    className (value) {
      return {
        'm-accordion__item--no-active': this.filtersChecked.includes(value)
      }
    },
    isColorFilter (filter) {
      return filter === config.attributesCodes.color
    },
    isBrandFilter (filter) {
      return filter === config.attributesCodes.pimBrandId || filter === 'brand'
    },
    clearSearch () {
      this.searchString = ''
    },
    filtersOpen (filters, filterType, activeSearchBrand = false) {
      if (isServer) return false;

      if (activeSearchBrand) {
        return true;
      } else {
        const localFilterType = JSON.parse(localStorage.getItem('category/filters/filterType') || '[]');
        return localFilterType.includes(filterType);
      }
    },
    getFilters (filtersList, filterType) {
      const filters = [...filtersList]

      if (filterType !== 'price' && !isServer) {
        const open = { open: true };

        if (filters.some(e => e.open === true)) {
          filters.splice(-1, 1);
        } else {
          filters.push(open);
        }
      }

      return filters;
    },
    countFiltersMore (filters) {
      const count = Object.keys(filters).length;
      return filters.some(e => e.open === true) ? count - 6 : count - 5;
    },
    changeMinimalPrice (value) {
      this.minimalPrice = value === '' ? '' : Math.trunc(value);
    },

    changeMaximalPrice (value) {
      this.maximalPrice = value === '' ? '' : Math.trunc(value);
    },
    submitFilterPrice () {
      if (this.minimalPrice === '') {
        this.minimalPrice = this.availableFilters.price.to
      } else {
        this.minimalPrice = Number(this.minimalPrice) >= this.minMaxPrice?.max
          ? Number(Math.trunc(this.minMaxPrice?.max)) : Number(this.minimalPrice)
      }
      if (this.maximalPrice === '') {
        this.maximalPrice = Number(this.availableFilters.price.from) + 1
      }

      this.$emit('submit-filter-price', this.minimalPrice, this.maximalPrice)
      this.minimalPrice = ''
      this.maximalPrice = ''
    },
    getFilterHref (filter) {
      if (!this.linkBuilder) return '#'
      return this.getIsFilterDisable(filter) || this.isFilterActive(filter) ? '#' : getFilterLink(filter)
    },
    getFilterComponent (filter) {
      if (!this.linkBuilder) return 'div'
      return this.getIsFilterDisable(filter) || this.isFilterActive(filter) ? 'div' : 'a'
    },
    getIsFilterDisable (filter) {
      return filter.count === 0 && !this.isFilterActive(filter)
    },
    changeSearchBrands (search) {
      this.activeSearchBrand = search.length > 0
    },
    disablePimBrandId (pimBrandid) {
      return (this.hideOnBrandPage || this.isVarusCafePage) && pimBrandid === 'pim_brand_id'
    }
  },
  computed: {
    ...mapGetters({
      getCurrentFilters: 'category-extension/getCurrentFilters',
      getAggregations: 'category-extension/getAggregations'
    }),
    minMaxPrice () {
      if (this.priceFromFilter) {
        return {
          min: Math.round(this.availableFilters?.price?.from || 0),
          max: Math.round(this.availableFilters?.price?.to || 0)
        }
      }

      return {
        min: Math.round(this.getAggregations?.agg_price?.filter?.price_min?.value || 0),
        max: Math.round(this.getAggregations?.agg_price?.filter?.price_max?.value || 0)
      }
    },
    priceSubmitDisabled () {
      return !(Number(this.minimalPrice) >= 0 &&
        Number(this.maximalPrice) > 0 &&
        this.minimalPrice <= this.maximalPrice)
    },
    isFilterActive () {
      let filters = this.getCurrentFilters

      if (Object.keys(this.activedFilters).length > 0) filters = this.activedFilters

      return filter =>
        castArray(filters[filter.type]).find(
          variant => variant && variant.id.toString() === filter.id.toString()
        ) !== undefined;
    },
    filteredBrandsFilters () {
      const brandKey = this.availableFilters.hasOwnProperty(config.attributesCodes.pimBrandId)
        ? config.attributesCodes.pimBrandId : 'brand'

      const brandFilters = this.availableFilters[brandKey] || []
      let filteredFilters = []

      if (this.searchString) {
        filteredFilters = brandFilters.filter(e => e.label?.toLowerCase().includes(this.searchString.toLowerCase()))
      } else {
        filteredFilters = brandFilters
      }

      return filteredFilters
    }
  },
  watch: {
    minMaxPrice: {
      immediate: true,
      handler: function (val) {
        this.minimalPrice = val?.min || 0
        this.maximalPrice = val?.max || 0
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.m-accordion {
  &__label {
    font-weight: var(--font-medium);
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    position: relative;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    transition: none;

    &-wrapper {
      max-height: px2rem(200);
      overflow-y: auto;
      overflow-x: hidden;

      &--open {
        max-height: px2rem(400);
      }
    }
  }

  &__input {
    display: none;
  }

  &__item {
    position: relative;
    border-bottom: 1px solid var(--line-gray);
    padding: var(--spacer-20) 0;

    &--active {
      .m-accordion__content {
        opacity: 1;
        max-height: 1000px;
        overflow: hidden;
        transition: 0.25s ease-in-out;
      }
    }

    &--no-active {
      .m-accordion__content {
        position: relative;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        transition: none;
      }

      ::v-deep {
        .sf-chevron {
          transform: translate3d(0, 0, 0) rotate(180deg) scale(0.6);
        }
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .search-input {
    position: relative;

    .brand-input {
      margin-top: var(--spacer-15);
    }

    .cancel-button {
      position: absolute;
      right: var(--spacer-5);
      top: var(--spacer-3);
      padding: var(--spacer-10);
      z-index: 1;
    }

    ::v-deep {
      input {
        height: var(--spacer-40);
      }

      .sf-input--filled {
        --input-background: var(--white);
      }

      .sf-input__wrapper {
        margin: 0;
      }

      .sf-input__error-message {
        display: none;
      }
    }
  }

  .price-filters {
    margin-top: var(--spacer-20);

    .price-filters-inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__line {
        display: block;
        width: var(--spacer-12);
        height: 1px;
        background-color: var(--black);
      }

      &__input {
        min-width: px2rem(80);
        max-width: px2rem(80);
      }
    }

    &__button {
      margin-top: var(--spacer-10);
      width: 100%;
      height: px2rem(40);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--orange);
      font-size: var(--font-size-14);
      color: var(--white);
      --button-text-decoration: none;

      &-disabled {
        opacity: 0.4;
      }

      &-clear {
        margin-top: var(--spacer-20);
        font-size: var(--font-size-14);
        font-weight: normal;
        color: var(--orange);
        --button-text-decoration: none;

        &:active {
          --button-background: none;
        }
      }
    }

    ::v-deep {
      input {
        padding: var(--spacer-11);
        height: var(--spacer-40);
      }

      .sf-input--filled {
        --input-background: var(--white);
      }

      .sf-input__wrapper {
        margin: 0;
      }

      .sf-input__error-message {
        display: none;
      }
    }
  }

  .filters {
    &__button-more {
      width: auto;
      height: auto;
      margin-top: var(--spacer-15);
      font-size: var(--font-sm);
      color: var(--orange);
      font-weight: 400;
      padding: 0;
      background: none;
      border: none;

      &--count {
        margin-left: var(--spacer-5);
      }
    }

    &__item {
      padding: var(--spacer-16) 0 0 0;

      &--disable {
        pointer-events: none;
        opacity: 0.5;
      }

      @include for-desktop {
        &:hover {
          --filter-label-color: var(--orange-hover);
          --filter-count-color: var(--orange-hover);
        }
      }
    }
  }
}

::v-deep {
  .sf-chevron {
    width: var(--spacer-8);
    transform: translate3d(0, 0, 0) rotate(0deg) scale(0.6);
    transition: transform .1s ease;
  }

  .sf-icon {
    --icon-width: 12px;
    --icon-height: 12px;
  }

  .sf-filter {
    --filter-label-margin: 0 0 0 var(--spacer-10);
    --filter-label-text-decoration: none;
    --filter-label-transform: none;
    --filter-label-color: var(--black);
    --filter-label-font-size: var(--font-sm);
    --filter-label-font-weight: var(--font-normal);
    --filter-count-margin: 0 0 0 var(--spacer-15);
    --filter-count-color: var(--dark-gray);
    --filter-count-font-size: var(--font-sm);

    &__label {
      font-size: var(--font-sm);
    }

    &__count, &__label {
      transition: color 0.3s ease-in-out;
    }
  }
}
</style>
