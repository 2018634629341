<template>
  <div class="a-section-tabs">
    <SfButton
      v-for="(item, i) in items"
      :key="i"
      :class="className(item.name)"
      @click="setActive(item.name)"
      data-transaction-name="Section Tab"
      class="sf-button--pure a-section-tabs__item"
      v-if="item.name!=='Recipe'"
    >
      {{ item.name }}
      <span v-if="item.count">
        {{ `(${item.count})` }}
      </span>
    </SfButton>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'ASectionTabs',
  components: { SfButton },
  props: {
    activeTab: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    count: {
      type: String,
      default: ''
    }
  },
  methods: {
    className (name) {
      return {
        'a-section-tabs__item--active': name === this.activeTab
      }
    },
    setActive (tabName) {
      this.$emit('click', tabName)
    }
  }
};
</script>
<style lang="scss">

.a-section-tabs {
  display: flex;

  &__item {
    color: var(--orange);
    font-weight: var(--font-normal);
    margin: 0 1.563rem var(--spacer-base) 0;
    position: relative;

    &:after {
      content: '';
      left: 0;
      right: 0;
      bottom: calc(-1 * var(--spacer-base));
      position: absolute;
      height: 3px;
      background-color: var(--orange);
      opacity: 0;
      transition: .2s ease;
    }

    &--active {
      color: var(--black);

      &:after {
        opacity: 1;
      }
    }

    span {
      padding-left: var(--spacer-2xs);
    }
  }
}

</style>
