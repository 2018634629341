<template>
  <div class="m-filter-categories">
    <AFilterCategoryItems
      :items="preparedCategories"
      :level="firstLevel"
      :show-all-goods="true"
      :total-products="totalProducts"
      @select="categorySelectHandler"
    />
  </div>
</template>

<script>
import { prepareCategoryMenuItem } from 'theme/helpers';
import DeviceType from 'theme/mixins/DeviceType';
import { isServer } from '@vue-storefront/core/helpers';
import AFilterCategoryItems from 'theme/components/atoms/a-filter-category-items';
import { mapState } from 'vuex';

export default {
  name: 'MSearchFilterSearch',
  components: {
    AFilterCategoryItems
  },
  mixins: [DeviceType],
  props: {
    currentCategory: { type: Number, default: null },
    categories: { type: Array, required: true },
    categoriesProductsCount: { type: Object, required: true },
    totalProducts: { type: Number, required: true },
    openCategories: { type: Array, required: false },
    show: { type: Boolean, required: true }
  },
  data () {
    return {
      timer: null,
      openedCategories: [],
      currentFilterCategories: []
    }
  },
  provide () {
    const provided = {};

    // Reactive injection
    Object.defineProperties(provided, {
      productsCount: {
        get: () => this.categoriesProductsCount
      },
      activeCategoryId: {
        get: () => this.currentCategory || null
      },
      openedCategories: {
        get: () => this.openedCategories
      }
    })

    return { provided };
  },
  mounted () {
    this.openedCategories = this.openCategories;
  },
  watch: {
    show (prev, next) {
      if (prev || prev === next) return

      this.$nextTick(() => {
        this.autoScrollCategory()
      })
    },
    categories (prev, next) {
      if (prev?.length === next?.length) return
      this.autoScrollCategory()
    }
  },
  computed: {
    ...mapState({
      getCategories: state => state.category.list
    }),
    firstLevel () {
      return 1
    },
    preparedCategories () {
      return this.prepareCategories(this.rootCategories);
    },
    rootCategories () {
      return this.categories;
    },
    parentIdGroups () {
      const groups = {};

      for (const category of this.categories) {
        if (groups[category?.parent_id] === undefined) {
          groups[category?.parent_id] = [];
        }

        groups[category?.parent_id].push(category);
      }

      return groups;
    }
  },
  methods: {
    prepareCategories (categories) {
      return categories
        .map(category => {
          const preparedCategory = prepareCategoryMenuItem(category)

          preparedCategory.items = this.parentIdGroups[preparedCategory.id] !== undefined
            ? this.prepareCategories(this.parentIdGroups[preparedCategory.id])
            : [];

          return preparedCategory
        })
    },
    categorySelectHandler (category) {
      this.openedCategories = category === null
        ? []
        : this.getCategoryNamesFromPath(category.path);

      this.$emit('select', category);
    },
    getCategoryNamesFromPath (path) {
      const categoryIds = path.split('/');

      return this.categories
        .filter((category) => categoryIds.includes(String(category.id)))
        .map((category) => category.name);
    },
    autoScrollCategory () {
      if (isServer) return
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (document.querySelector('.m-filter-categories .accordion-item__header--active') !== null) {
          const el = document.querySelector('.m-filter-categories .accordion-item__header--active')
          const container = document.querySelector('.m-filter-categories .sf-accordion')

          container.scrollTo({
            top: el.offsetTop - container.offsetTop,
            left: 0,
            behavior: 'smooth'
          })

          clearInterval(this.timer);
        }
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.m-filter-categories {
  padding: 0;
  margin: 0 0 18px;
  border-radius: var(--spacer-10);
  overflow: hidden;

  @media only screen and (min-width: $tablet-min) {
    margin: 0 0 10px;
  }

  ::v-deep .accordion {
    font-family: Inter,serif;
    display: block;
    background: var(--accordion-background);
    width: auto;
    padding-right: .25rem;
    padding-left: .25rem;
    align-items: center;

    @include for-desktop {
      padding-right: 0;
      padding-left: 0;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(235, 103, 71, 0.3);
    }

    &-item {
      &:first-child {
        @include for-desktop {
          padding-top: var(--spacer-10);
          margin-left: var(--spacer-20);
        }

        & > .accordion-item__header {
          @include for-mobile {
            padding-left: var(--spacer-15);
          }
        }
      }

      &:not(:first-child) {
        margin-left: var(--spacer-15);

        @include for-desktop {
          margin-left: var(--spacer-20);
          margin-right: var(--spacer-20);
        }
      }

      &__header {
        color: var(--black);
        white-space: normal;
        padding: var(--spacer-10) 0;
        font-style: normal;
        font-weight: normal;
        font-size: var(--font-sm);
        display: inline-block;
        border-radius: var(--spacer-10);
        position: relative;
        width: 80%;
        transition: color 0.3s ease-in-out;
        cursor: pointer;

        .accordion-item__label {
          position: relative;

          &-count {
            color: var(--neutral-gray);
            padding-left: var(--spacer-10);
            position: absolute;
            left: 100%;
            bottom: 0;
            transition: color .3s ease-in-out;
          }
        }

        &.is-open {
          color: var(--orange);
          background-color: inherit;
        }

        @include for-desktop {
          &:hover {
            color: var(--orange-hover);

            .accordion-item__label {

              &-count {
                color: var(--orange);
              }
            }
          }
        }

        &--active {
          color: var(--orange);

          .accordion-item__label {

            &-count {
              color: var(--orange);
              transition: color .3s ease-in-out;
            }
          }
        }
      }

      .sf-accordion-item__content {
        padding-bottom: 0;
        padding-top: 0;
        border: none;
        border-left: 1px solid var(--gray);

        & > .accordion--sub {
          margin-left: var(--spacer-20);

          .accordion-item__header.is-open {
            border-radius: 0;
          }
        }
      }

      .accordion-item__first-level + .sf-accordion-item__content {
        border-left: none;
        position: relative;
      }

      .accordion--sub {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: var(--gray);
        }
      }

      &:last-child {
        @include for-desktop {
          padding-bottom: var(--spacer-20);
        }
      }

      &--sub {
        .accordion-item__header {
          .accordion-item__label {
            &-count {
              bottom: - px2rem(3);
            }
          }
        }
      }
    }
  }
}
</style>
