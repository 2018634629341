<template>
  <SfHeading class="sf-heading--left a-search-page-result-empty">
    <template #title>
      <p class="sf-heading__title sf-heading__title--h3">
        {{ $t("We're sorry, but your search for «{query}» didn't match", { query }) }}
      </p>
    </template>
    <template #subtitle>
      <p class="sf-heading__subtitle">
        {{ $t('Correct your request or') }}
        <SfLink link="/" class="sf-link--primary">
          {{ $t('go to the Main page') }}
        </SfLink>
      </p>
    </template>
  </SfHeading>
</template>
<script>
import { SfHeading, SfLink } from '@storefront-ui/vue';
export default {
  name: 'ASearchPageResultEmpty',
  components: {
    SfHeading,
    SfLink
  },
  props: {
    query: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@storefront-ui/shared/styles/helpers/breakpoints';

.a-search-page-result-empty {
  padding: 0 var(--spacer-16) var(--spacer-40);
}
.sf-heading {
  &__title {
    font-weight: var(--font-medium);
    line-height: normal;
    margin-bottom: var(--spacer-20);
    color: var(--black);
  }

  &__title--h3 {
    --heading-title-font-size: var(--font-size-20);

    @media (min-width: $tablet-min) {
      --heading-title-font-size: var(--font-size-32);
    }
  }

  &__subtitle {
    --heading-subtitle-font: var(--font-sm);

    @media (min-width: $tablet-min) {
      --heading-subtitle-font: var(--font-base);
    }
  }
}

::v-deep {
  .sf-link.sf-link--primary {
    font-size: var(--font-base);
  }
}
</style>
