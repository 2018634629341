<template>
  <div
    v-if="hasSelectedFilters"
    class="m-category-filters-selected"
  >
    <div
      v-for="(filters, key) in filterCategories"
      :key="key"
    >
      <div
        class="filter"
        v-for="filter in filters"
        :key="key + filter.id"
      >
        <div class="filter__content">
          <template v-if="filter.attribute_code === 'price' || filter.id === 'price'">
            <span class="filter__text">
              {{ $t('Price') }}, грн: <span>{{ selectedPrice.join('-') }}</span>
            </span>
          </template>
          <template v-else>
            <span class="filter__text">
              {{ filterLabels[key] }}: <span>{{ filter.label | capitalize }}</span>
            </span>
          </template>
          <SfButton
            class="filter__remove sf-button--text"
            @click="changeFilter(filter)"
          >
            <SfIcon
              icon="cross"
              size="13px"
            />
          </SfButton>
        </div>
      </div>
    </div>

    <SfButton
      class="clear-all sf-button--text"
      @click="$emit('clear-all')"
    >
      {{ $t('Clear filters') }}
    </SfButton>
  </div>
</template>

<script>
import { SfIcon, SfButton } from '@storefront-ui/vue';

export default {
  name: 'MCategoryFiltersSelected',
  components: {
    SfButton,
    SfIcon
  },
  props: {
    availableFilters: {
      type: Object,
      default: () => ({})
    },
    filterCategories: {
      type: Object,
      required: true
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    filterPrice: {
      type: Object,
      default: () => ({})
    },
    searchPage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedPrice () {
      if (this.searchPage) {
        return [this.filterCategories?.price?.[0]?.from, this.filterCategories?.price?.[0]?.to]
      }

      let priceSelected = []

      const priceParam = this.$route.params.price

      if (Object.keys(this.filterPrice).length > 0) {
        priceSelected = this.filterPrice.id.split('-')
      } else if (priceParam !== undefined) {
        if (Array.isArray(priceParam)) {
          priceSelected = priceParam[0].split('-')
        } else {
          priceSelected = priceParam.split('-')
        }
      }

      return priceSelected
    },
    hasSelectedFilters () {
      return Object.keys(this.filterCategories).length > 0;
    }
  },
  methods: {
    changeFilter (filter) {
      this.$emit('clear', {
        filter,
        active: true
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.m-category-filters-selected {
  padding: var(--spacer-15) 0 var(--spacer-25);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .filter {
    display: inline-block;
    padding: var(--spacer-6) var(--spacer-11);
    margin: var(--spacer-5) var(--spacer-15) var(--spacer-5) 0;

    border: 1px solid var(--gray-transparent);
    box-sizing: border-box;
    border-radius: var(--spacer-5);

    &__content {
      display: flex;
      align-items: center;
    }

    &__text {
      --font-family-primary: var(--font-family-inter);
      font-size: var(--font-size-13);
      line-height: var(--font-size-16);
      color: var(--black);
      padding-right: var(--spacer-11);

      span {
        font-weight: 600;
      }
    }

    &__remove {
      outline: none;

      ::v-deep .sf-icon {
        --icon-color: var(--dark-gray);
      }

      &:hover {
        ::v-deep .sf-icon {
          --icon-color: var(--c-primary);
        }
      }

      &:active {
        --button-background: var(--white);
      }
    }
  }

  .clear-all {
    --property-name-font-weight: var(--font-medium);
    font-size: var(--font-size-14);
    line-height: var(--font-size-17);
    color: var(--c-primary);
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--orange-hover);
    }

    &:active {
      --button-background: var(--white);
      --button-color: var(--c-primary);
    }

    &.sf-button--text {
      text-decoration: unset;
    }
  }
}
</style>
