<template>
  <div class="radio-switcher-text">
    <SfRadio
      v-for="option in availableFilters"
      :key="option.id"
      :value="String(option.id)"
      :label="option.label"
      :selected="String(getCurrentCategory.id)"
      @input="changeVarusCafeCategory"
    />
  </div>
</template>

<script>
import { SfRadio } from '@storefront-ui/vue';
import { mapGetters, mapState } from 'vuex';

export default {
  nsme: 'MVaruscafeMobileFilterInner',
  components: {
    SfRadio
  },
  props: {
    isFilterActive: {
      type: Function
    },
    availableFilters: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      rootCategory: null
    }
  },
  computed: {
    ...mapState({
      getCategories: state => state.category.list
    }),
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory'
    })
  },
  methods: {
    changeVarusCafeCategory (id) {
      const getLink = this.availableFilters.find(item => +item.id === +id)

      this.$router.push(getLink.link)
    }
  }
}
</script>
